<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-primary shadow-primary border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">auto_graph</v-icon>
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">Andamento Magazzino</h6>
        <p class="font-weight-light text-secondary text-sm">Analytics Insights</p>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas ref="warehouseChart" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: 'WarehouseData',
  props: {
    warehouseData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chart: null
    };
  },
  watch: {
    warehouseData: {
      handler(newData) {
        if (newData && Object.keys(newData).length) {
          this.createChart(); // Crea il grafico quando i dati arrivano
        }
      },
      deep: true,
      immediate: true // Monta il grafico se i dati sono già disponibili
    }
  },
  methods: {
    createChart() {
      // Distruggi il grafico precedente se esiste
      if (this.chart) {
        this.chart.destroy();
      }

      const labels = this.warehouseData.labels || [];  // Etichette delle date
      const bottiglie = this.warehouseData.bottiglie || [];  // Bottiglie
      const valore = this.warehouseData.valore || [];  // Valore
      const costo = this.warehouseData.costo || [];  // Costo

      let ctx = this.$refs.warehouseChart.getContext("2d");

      // Crea il grafico
      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,  // Le date come etichette
          datasets: [
            {
              label: 'Giacenza Bottiglie',
              data: bottiglie,
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
              yAxisID: 'y'
            },
            {
              label: 'Valore Bottiglie',
              data: valore,
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 1,
              yAxisID: 'y1'
            },
            {
              label: 'Costo Bottiglie',
              data: costo,
              backgroundColor: 'rgba(255, 159, 64, 0.2)',
              borderColor: 'rgba(255, 159, 64, 1)',
              borderWidth: 1,
              yAxisID: 'y1'
            }
          ]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
              position: 'left',  // Posiziona l'asse y a sinistra
              title: {
                display: true,
                text: 'Giacenza Bottiglie'
              }
            },
            y1: {
              beginAtZero: true,
              position: 'right',  // Posiziona l'asse y1 a destra
              title: {
                display: true,
                text: 'Valore / Costo'
              },
              grid: {
                drawOnChartArea: false  // Evita la sovrapposizione delle griglie tra gli assi
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  label += context.parsed.y;
                  return label;
                }
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  }
};
</script>
