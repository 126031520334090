<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-primary shadow-primary border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >bar_chart</v-icon
        >
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">Vini più Venduti</h6>
        <p class="font-weight-light text-secondary text-sm">
          Quantità vendute
        </p>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas id="horizontal-bar-chart" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: "horizontal-bar-chart",
  props: {
    dataBarChart: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      chartId: "horizontal-bar-chart",
      chart: null,
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    dataBarChart: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.createChart();
        }
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      // Verifica che i dati esistano prima di eseguire map
      const wineNames = (this.dataBarChart.wine_names || []).map((name, index) => {
        const winemaker = this.dataBarChart.winemaker ? this.dataBarChart.winemaker[index] : '';  // Aggiungi il nome del winemaker
        const vintage = this.dataBarChart.wine_vintage ? this.dataBarChart.wine_vintage[index] : '';  // Aggiungi l'annata (vintage)

        return `${name} (${vintage} / ${winemaker})`;  // Combina il nome del vino, l'annata e il produttore
      });
      const wineCounts = this.dataBarChart.wine_counts || [];

      var ctx = document.getElementById(this.chartId).getContext("2d");

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: wineNames,  // Etichette dei nomi dei vini
          datasets: [
            {
              label: "Quantità venduta",
              backgroundColor: "#3A416F",
              data: wineCounts,  // Dati delle quantità vendute
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 20
            }
          ]
        },
        options: {
          indexAxis: 'y',  // Imposta l'asse Y come l'asse principale per creare barre orizzontali
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: true
              },
              ticks: {
                padding: 10,
              }
            },
            y: {
              grid: {
                display: true
              },
              ticks: {
                padding: 10,
              }
            }
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              display: true,
              align: 'end',
              anchor: 'end',
              color: 'black',
              formatter: (value) => {
                return value;  // Mostra il valore come etichetta
              },
              padding: {
                right: 10
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
    }
  }
};
</script>
