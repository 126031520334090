<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-primary shadow-primary border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >bar_chart</v-icon
        >
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">Migliori Produttori</h6>
        <p class="font-weight-light text-secondary text-sm">
          Migliori produttori con il più alto numero di bottiglie vendute
        </p>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas id="horizontal-bar-chart-winemaker" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "horizontal-bar-chart-winemaker",
  props: {
    dataBarChart: {
      type: Array, // Ora un array con produttori e totale bottiglie vendute
      default: () => []
    }
  },
  data() {
    return {
      chartId: "horizontal-bar-chart-winemaker",
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    dataBarChart: {
      deep: true,
      handler() {
        this.createChart();
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      const winemakerNames = this.dataBarChart.map(item => item[0]); // Nomine produttori
      const winemakerTotals = this.dataBarChart.map(item => item[1].total_bottles); // Totale bottiglie per produttore

      // Verifica se il canvas è presente nel DOM
      const canvasElement = document.getElementById(this.chartId);
      if (!canvasElement) {
        console.error("Canvas element not found!");
        return;
      }

      const ctx = canvasElement.getContext("2d");
      if (!ctx) {
        console.error("Unable to get canvas context!");
        return;
      }

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: winemakerNames,
          datasets: [
            {
              label: "Totale bottiglie vendute",
              backgroundColor: "#3A416F",
              data: winemakerTotals,
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 20
            }
          ]
        },
        options: {
          indexAxis: 'y', // Barre orizzontali
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: true
              }
            },
            y: {
              grid: {
                display: true
              }
            }
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: true,
              align: 'end',
              anchor: 'end',
              color: 'black',
              formatter: value => value,
              padding: {
                right: 10
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
    }
  }
};
</script>
