<template>
  <div>
    <v-container fluid class="ml-0">
      <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
      </v-overlay>
      <v-row>
        <v-col>
          <v-card class="card-shadow border-radius-xl" elevation="2">
            <div class="card-header-padding">
              <div class="font-weight-bold text-h3 text-typo mb-0 text-center">
                <date-range-picker
                  v-model="dateRange"
                  :linked-calendars="false"
                  :locale-data="localData"
                  :autoApply="false"
               />
<!--                <v-text-field label="Da"></v-text-field>
                <v-text-field label="A"></v-text-field>-->
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pieChart
            :tot_fatturato="fatturato"
            :tot_bottiglie="bottiglieVendute"
            :percentuali="percentage"
            >
          </pieChart>
        </v-col>
        <v-col>
          <pieChart2 :data="priceRangeSales" :price_cost="total_cost" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <mixedChart :dataMixed="mixedData" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <warehouseData :warehouseData="warehousesData"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <torta-download-type :chart-data="downloadType"></torta-download-type>
        </v-col>
        <v-col>
          <torta-horeca-vino-cibo :chart-data="horeca" ></torta-horeca-vino-cibo>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <best-bottle :data-bar-chart="topPriceListItemsData"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <best-winemaker2 :data-bar-chart="miglioriProduttori"  />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <best-winemaker :data-bar-chart="miglioriProduttori"  />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
import 'moment/locale/it';
import pieChart from "@/views/Pages/Reports/VVPieChart.vue"
import pieChart2 from "@/views/Pages/Reports/VVPieChart2.vue"
import mixedChart from "@/views/Pages/Reports/VVMixedChart.vue"
import StatsService from "@/services/stats.service";
import warehouseData from "@/views/Pages/Reports/VVMixedChartMagazzino.vue"
import TortaDownloadType from "@/views/Pages/Reports/TortaDownloadType.vue";
import TortaHorecaVinoCibo from "@/views/Pages/Reports/TortaHorecaVinoCibo.vue";
import bestBottle from "@/views/Pages/Reports/VVMixedChartBestBottle.vue"
import bestWinemaker from '@/views/Pages/Reports/VVMixedChartBestWinemaker.vue'
import bestWinemaker2 from '@/views/Pages/Reports/VVMixedChartBestWinemaker2.vue'

export default {
  name:'Analytics',
  components: {
    DateRangePicker,
    pieChart,
    pieChart2,
    mixedChart,
    warehouseData,
    TortaDownloadType,
    TortaHorecaVinoCibo,
    bestBottle,
    bestWinemaker,
    bestWinemaker2,
  },
  data () {
    return {
      progress: false,
      // datepicker
      date: "", // (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      search: null,
      dateRange: {
          startDate:'',
          endDate: '',
      },
      localData: {
        format: 'dd-mmm-yyyy',
        separator: ' | ',
        applyLabel: 'Applica',
        cancelLabel: 'Cancella',
        fromLabel: 'Da',
        toLabel: 'A',
        customRangeLabel: 'Personalizzato',
        weekLabel: 'Sett',
        daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        monthNames: [
          'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
          'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
        ],
        firstDay: 1,
        todayLabel: 'Oggi',
        yesterdayLabel: 'Ieri'
      },
      // dati
      fatturato:'0.00',
      bottiglieVendute:0,
      miglioriProduttori:[],
      total_cost:0,
      percentage:[],
      quantity:0,
      priceRangeSales:[],
      colorMapping: {
        "10-20€":"#03A9F4", "20-30€":"#e91e63", "30-40€":"#3A416F", "40-50€":"#a8b8d8"
      },
      mixedData:{},
      downloadType: {},
      horeca:[],
      warehousesData:{},
      bestBottle: []
    }
  },
  mounted () {
    console.log('moment local', moment.locale())
    moment.locale('it')
    console.log('moment local', moment.locale())
  },
  watch: {
    dateRange(val) {
      this.getAnalytics()
      console.log(val)
    }
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      const date = new Date(value);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() restituisce mesi da 0 (gennaio) a 11 (dicembre)
      const year = date.getFullYear().toString().substring(2);
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      // Formatta la data nel nuovo formato
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    }
  },
  computed: {
    computedDateFormatted() {
      return this.date ? moment(this.date).locale('it').format('dddd, DD MMMM YYYY') : ''
    },
    topPriceListItemsData() {
      // Trasforma i dati in due array: uno per i nomi dei vini e uno per le quantità
      return {
        wine_names: this.bestBottle.map(item => item.wine_name),
        wine_vintage: this.bestBottle.map(item => item.vintage),
        winemaker: this.bestBottle.map(item => item.winemaker),
        wine_counts: this.bestBottle.map(item => item.count)
      };
    },
    topWinemakerData() {
    // Mappa i dati che arrivano dall'API
    return {
      winemaker_names: this.miglioriProduttori.map(item => item.price_list_item__wine__winemaker__name),
      winemaker_counts: this.miglioriProduttori.map(item => item.count)
    };
  },
  },
  methods: {
    formatCurrency(value) {
    // Crea un formattatore per numeri in euro
      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        // Qui puoi specificare altre opzioni, come il numero minimo di cifre decimali
        minimumFractionDigits: 2
      });

    // Utilizza il formattatore per convertire il numero in una stringa formattata
      return formatter.format(value);
    },
    dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() > new Date()
        }
        return classes
      },
    getAnalytics() {
  const params = {
    store_id: this.$store.state.auth.user.store_id,
    start_date: this.dateRange.startDate.toISOString(),
    end_date: this.dateRange.endDate.toISOString(),
  };

  StatsService.getAnalyticsByDate(params).then(resp => {
    const totalRevenue = parseFloat(resp.data.total_incasso);  // Assicurati che sia un numero

    this.fatturato = this.formatCurrency(totalRevenue);
    this.bottiglieVendute = resp.data.total_bottles_sold;
    this.miglioriProduttori = resp.data.top_winemakers;
    this.total_cost = resp.data.total_price_cost;

    // Calcola percentuali e valori assoluti per ogni tipo di vino
    this.percentage = Object.entries(resp.data.wine_type_percentages).map(([key, value]) => {
      const absoluteValue = (value.percentage / 100) * totalRevenue;
      return {
        key,
        percent: value.percentage.toFixed(2),
        total_quantity: value.total_quantity,
        absolute_value: absoluteValue.toFixed(2)
      };
    });

    // Trasformare `horeca` in un array di oggetti per compatibilità con il componente
    this.horeca = Object.entries(resp.data.average_percentages).map(([key, value]) => {
      const absoluteValue = (value / 100) * totalRevenue;
      return {
        type: key,  // es. Vino, Cibo, Bibite
        percent: value.toFixed(2),
        absolute_value: absoluteValue.toFixed(2)
      };
    });

    console.log("Horeca Data:", this.horeca);

    // Mappatura dei dati per le vendite per fascia di prezzo
    this.priceRangeSales = resp.data.price_range_sales.map(item => ({
      ...item,
      color: this.colorMapping[item.range] || '#000'
    }));

    this.mixedData = resp.data.data_grafico_barre;
    this.downloadType = resp.data.category_counts;
    this.bestBottle = resp.data.top_price_list_items;

    console.log("Warehouse Data:", resp.data.warehouse_data);
    this.warehousesData = resp.data.warehouse_data;
  });
}
  }
}
</script>
<style scoped>
.custom_date_range {
  min-height: 40px;
  min-width: 300px;
  border: 2px;
}

</style>