<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
          color="bg-gradient-warning shadow-warning border-radius-xl mt-n6"
          class="shadow"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">donut_small</v-icon>
      </v-avatar>
      <div class="ms-4">
        <h4 class="text-h6 text-typo font-weight-bold">Tipi di Scarico</h4>
        <p class="font-weight-light text-secondary text-sm">
          Distribuzione delle tipologie di scarico
        </p>
      </div>
    </div>
    <div class="card-padding py-0 d-flex">
      <div class="w-50 ms-auto">
        <v-simple-table class="table" height="300px">
          <template v-slot:default>
            <tbody>
            <tr v-for="item in formattedChartData" :key="item.type">
              <td>
                <div class="d-flex px-2 py-0 align-center">
                  <div>
                    <h6 class="mb-0 ms-2 text-sm text-typo font-weight-bold">
                      {{ item.type }}
                    </h6>
                  </div>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                  <span class="text-xs font-weight-bold text-body">
                    {{ item.value }}
                  </span>
              </td>
              <td class="align-middle text-center text-sm">
                  <span class="text-xs font-weight-bold text-body">
                    {{ item.percentage }}%
                  </span>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="chart w-50 pb-4">
        <canvas ref="pie-chart" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "TortaDownloadType",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    formattedChartData() {
      const total = Object.values(this.chartData).reduce((acc, val) => acc + val, 0);
      return Object.keys(this.chartData).map(key => {
        const value = this.chartData[key];
        const percentage = total ? ((value / total) * 100).toFixed(2) : 0;
        const formattedKey = key.replace('_count', '');
        return {
          type: formattedKey,
          value: value,
          percentage: percentage
        };
      });
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.createChart(); // Ricrea il grafico quando i dati cambiano
      }
    }
  },
  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
      const labels = this.formattedChartData.map(item => item.type);
      const data = this.formattedChartData.map(item => item.value);
      const backgroundColor = ["#e91e63", "#03A9F4", "#3A416F", "#a8b8d8", '#8B0000'];

      if (this.chart) {
        this.chart.destroy(); // Distruggi il vecchio grafico se esiste
      }

      this.chart = new Chart(this.$refs['pie-chart'].getContext('2d'), {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: "Tipi di Scarico",
            data: data,
            backgroundColor: backgroundColor,
            borderWidth: 2
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 10,   // Aumenta lo spazio sopra il grafico
              bottom: 30 // Aumenta lo spazio sotto il grafico (tra la torta e la legenda)
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                boxWidth: 20,
                padding: 30  // Aumenta lo spazio tra la legenda e il grafico
              }
            },
            datalabels: {
              color: '#000000',
              align: 'end',
              anchor: 'end',
              formatter: (value, ctx) => {
                let label = ctx.chart.data.labels[ctx.dataIndex];
                return label + ': ' + value;
              },
              font: {
                weight: 'bold',
                size: 14
              },
              offset: 10,
            }
          }
        },
        plugins: [ChartDataLabels]
      });
    }
  }
};
</script>

