<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
          color="bg-gradient-danger shadow-warning border-radius-xl mt-n6"
          class="shadow"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
        >donut_small
        </v-icon
        >
      </v-avatar>
      <div class="ms-4">
        <h4 class="text-h6 text-typo font-weight-bold">Fatturato Vino</h4>
        <p class="font-weight-light text-secondary text-sm">
          Analytics Insights
        </p>
      </div>
    </div>
    <div class="card-padding py-0 d-flex">
      <div class="w-50 ms-auto">
        <h1>{{ totalIncasso }}</h1>
        <h4>{{ totalBottles }} Bottiglie Vendute per un costo di {{ formatCurrency(price_cost) }}</h4>
        <v-simple-table class="my-custom-table" height="300px">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-left">Fascia di Prezzo</th>
              <th class="text-center">Percentuale</th>
              <th class="text-center">Bottiglie</th>
              <th class="text-center">Valore</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in data" :key="item.range">
              <td>
                <div class="d-flex px-2 py-0 align-center">
                  <h6 class="mb-0 ms-2 text-sm text-typo">
                    <!-- <span class="dot" :style="{ backgroundColor: item.color }"></span> -->
                    <span class="ml-2" :style="{ color: item.color }">{{ item.range }}</span>
                  </h6>
                </div>
              </td>
              <td class="align-middle text-center text-sm">
                {{ item.percentage.toFixed(2) }}%
              </td>
              <td class="align-middle text-center text-sm">
                {{ item.total_quantity }}
              </td>
              <td class="align-middle font-weight-bold text-center text-sm">
                {{ formatCurrency(item.total_value) }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="chart w-50 pb-0">
        <canvas ref="pie-chart2" class="chart-canvas" width="50" height="50"></canvas>
      </div>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "pie-chart2",
  props: {
    data: Array,
    price_cost:Number
  },
  data: function () {
    return {
      pieChartId: "pie-chart2",
      chart: null,

    };
  },
  watch: {
    data(newData, oldData) {
      if (newData !== oldData) {
        this.refreshChart();
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.createChart();
    })
  },
  computed: {
    totalIncasso() {
      const incasso = this.data.reduce((sum, item) => sum + item.total_value, 0).toFixed(2);
      return this.formatCurrency(incasso)
    },
    totalBottles() {
      return this.data.reduce((sum, item) => sum + item.sales, 0);
    }
  },
  methods: {
    formatCurrency(value) {
      // Crea un formattatore per numeri in euro

      const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        // Qui puoi specificare altre opzioni, come il numero minimo di cifre decimali
        minimumFractionDigits: 2
      });

      // Utilizza il formattatore per convertire il numero in una stringa formattata
      return formatter.format(value);
    },
    createChart() {
      this.chart = new Chart(this.$refs['pie-chart2'].getContext('2d'), {
        type: "pie",
        data: {
          labels: this.data.map(item => item.range),
          datasets: [{
            data: this.data.map(item => item.total_value),
            backgroundColor: ["#03A9F4", "#e91e63", "#3A416F", "#a8b8d8"],
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
        },
      })
    },
    refreshChart() {
      this.destroyChart();
      this.$nextTick(() => {
        this.createChart();
      });
    },
    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
      }
    }
  }
}
</script>
<style scoped>
.dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.my-custom-table .v-table__wrapper table {
  border-spacing: 0 2px;
}

.my-custom-table .v-table__wrapper tbody tr {
  height: auto;
}

.my-custom-table .v-table__wrapper tbody tr td {
  font-size: 10px;
  padding-top: 1px;
  padding-bottom: 1px;
}
</style>