<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-primary shadow-primary border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">bar_chart</v-icon>
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">Migliori Produttori</h6>
        <p class="font-weight-light text-secondary text-sm">
          Migliori produttori con il più alto numero di bottiglie vendute
        </p>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas id="stacked-bar-chart-winemaker" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "stacked-bar-chart-winemaker",
  props: {
    dataBarChart: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      chartId: "stacked-bar-chart-winemaker",
      chart: null
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    dataBarChart: {
      deep: true,
      handler() {
        this.createChart();
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }

      // Estrai i nomi dei produttori e i dati sui vini
      const winemakerNames = this.dataBarChart.map(item => item[0]);  // Produttori
      const winesData = this.dataBarChart.map(item => item[1].wines); // Vini venduti per produttore

      // Crea un array di colori per differenziare i vini
      const colors = [
        "#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF", "#FF9F40",
        "#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"
      ];

      // Estrai i nomi di tutti i vini unici (per le barre impilate)
      const allWineNames = [...new Set(winesData.flat().map(wine => wine.wine_name))];

      // Crea dataset per ciascun vino
      const datasets = allWineNames.map((wineName, index) => ({
        label: wineName,
        backgroundColor: colors[index % colors.length],
        data: winemakerNames.map((_, i) => {
          // Trova il numero di bottiglie vendute di questo vino per ogni produttore
          const wine = winesData[i].find(w => w.wine_name === wineName);
          return wine ? wine.wine_count : 0;
        })
      }));

      // Verifica se il canvas è presente nel DOM
      const canvasElement = document.getElementById(this.chartId);
      if (!canvasElement) {
        console.error("Canvas element not found!");
        return;
      }

      const ctx = canvasElement.getContext("2d");
      if (!ctx) {
        console.error("Unable to get canvas context!");
        return;
      }

      // Crea il grafico a barre impilate
      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: winemakerNames,  // I nomi dei produttori
          datasets: datasets  // I dataset per ogni vino
        },
        options: {
          plugins: {
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                font: {
                  size: 10  // Riduce la dimensione del testo nella legenda
                }
              }
            }
          },
          responsive: true,
          scales: {
            x: {
              stacked: true // Barre impilate
            },
            y: {
              stacked: true, // Barre impilate
              beginAtZero: true
            }
          }
        }
      });
    }
  }
};
</script>
