<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-warning shadow-warning border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">donut_small</v-icon>
      </v-avatar>
      <div class="ms-4">
        <h4 class="text-h6 text-typo font-weight-bold">Fatturato Totale</h4>
        <p class="font-weight-light text-secondary text-sm">
          Analytics Insights
        </p>
      </div>
    </div>
    <div class="card-padding py-0 d-flex">
      <div class="w-50 ms-auto">
        <h1>{{ tot_fatturato }}</h1>
        <h4> {{ tot_bottiglie }} Bottiglie Vendute</h4>
        <v-simple-table class="table" height="300px">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in percentuali" :key="item.key">
                <td>
                  <div class="d-flex px-2 py-0 align-center">
                    <div>
                      <h6 class="mb-0 ms-2 text-sm text-typo font-weight-bold">
                        {{ item.key }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-xs font-weight-bold text-body">
                    {{ item.total_quantity }}
                  </span>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-xs font-weight-bold text-body">
                    {{ item.percent }}%
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="chart w-50 pb-4">
        <canvas ref="pie-chart" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa il plugin

export default {
  name: "pie-chart",
  props: {
    tot_fatturato: String,
    tot_bottiglie: Number,
    percentuali: Array
  },
  data: function () {
    return {
      pieChartId: "pie-chart",
      chart: null,
      shouldDisplayChart: false
    };
  },
  watch: {
    percentuali: {
      deep: true,
      handler() {
        this.createChart(); // Ricrea il grafico quando i dati cambiano
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const labels = this.percentuali.map(item => item.key);
      const data = this.percentuali.map(item => item.percent);
      const backgroundColor = ["#e91e63", "#03A9F4", "#3A416F", "#a8b8d8", '#8B0000'];

      if (this.chart) {
        this.chart.destroy(); // Distruggi il vecchio grafico se esiste
      }

      this.chart = new Chart(this.$refs['pie-chart'].getContext('2d'), {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            label: "Percentuale Tipo Vino",
            data: data,
            backgroundColor: backgroundColor,
            borderWidth: 2
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            },
            datalabels: { // Configura il plugin per visualizzare le etichette
              color: '#ffffff',
              formatter: (value, ctx) => {
                let label = ctx.chart.data.labels[ctx.dataIndex];
                return label + ': ' + value + '%';
              },
              font: {
                weight: 'bold',
                size: 14
              },
              align: 'center',
              anchor: 'center'
            }
          }
        },
        plugins: [ChartDataLabels] // Registra il plugin
      });
    }
  }
};
</script>
