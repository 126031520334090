<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-primary shadow-primary border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
          >auto_graph</v-icon
        >
      </v-avatar>
      <div class="ms-4">
        <h6 class="text-h6 text-typo font-weight-bold">Fatturato Comparativo Anno Precedente</h6>
        <p class="font-weight-light text-secondary text-sm">
          Analytics Insights
        </p>
      </div>
    </div>
    <div class="card-padding pt-0 px-4">
      <div class="chart">
        <canvas id="mixed-chart" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: "mixed-chart",
  props: {
    dataMixed: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      mixedChartId: "mixed-chart",
      chart: null,
    };
  },
  mounted() {
    this.createChart();
  },
  watch: {
    dataMixed: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.createChart();
        }
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
      if (this.chart) {
        this.chart.destroy();
      }

      // Verifica che i dati esistano prima di eseguire map
      const currentYearData = this.dataMixed.current_year_data || [];
      const previousYearData = this.dataMixed.previous_year_data || [];

      // Calcolo della variazione percentuale solo se entrambi i dataset sono disponibili
      const percentageChange = currentYearData.map((current, index) => {
        const previous = previousYearData[index] || 0;
        if (previous === 0) return 0;
        return (((current - previous) / previous) * 100).toFixed(2);  // Calcolo percentuale
      });

      var ctx = document.getElementById(this.mixedChartId).getContext("2d");

      this.chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.dataMixed.labels || [],  // Assicurati che le etichette siano definite
          datasets: [
          {
              label: "Anno Precedente",
              backgroundColor: "#e91e63",
              data: previousYearData,
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 20
            },
            {
              label: "Anno Corrente",
              backgroundColor: "#3A416F",
              data: currentYearData,
              borderRadius: 4,
              borderSkipped: false,
              maxBarThickness: 20
            },
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: true
              },
              stacked: false,
              barPercentage: 0.9,
              categoryPercentage: 0.5
            },
            y: {
              beginAtZero: true,
              min: 0,        // Valore minimo dell'asse Y
              max: 210000,     // Aumenta il valore massimo dell'asse Y
              ticks: {
                padding: 20,
                // callback: function(value) {
                //   return Math.round(value) // Arrotonda i valori
                // }
              }
            }
          },
          plugins: {
            legend: {
              display: true
            },
            datalabels: {
              display: true,
              align: 'end',
              anchor: 'end',
              color: 'gray',
              formatter: (value, ctx) => {
                const euroFormatter = new Intl.NumberFormat('it-IT', {
                  style: 'currency',
                  currency: 'EUR',
                  minimumFractionDigits: 0,  // Nessun decimale
                });
                if (ctx.dataset.label === "Anno Corrente") {
                  const percentage = `${Math.round(percentageChange[ctx.dataIndex])}%`;  // Arrotonda la percentuale
                  const actualValue = euroFormatter.format(value);  // Mostra il valore effettivo
                  return `${percentage}\n${actualValue}`;  // Mostra la percentuale e poi il valore sotto
                } else if (ctx.dataset.label === "Anno Precedente") {
                  const actualValue = euroFormatter.format(value);
                  return `${actualValue}`
                }
                return Math.round(value);
              },
              padding: {
                top: 10
              }
            }
          }
        },
        plugins: [ChartDataLabels]
      });
    }
  }
};
</script>
