<template>
  <v-card class="card-shadow border-radius-xl z-index-2">
    <div class="px-4 pt-2 pb-0 d-flex">
      <v-avatar
        color="bg-gradient-warning shadow-warning border-radius-xl mt-n6"
        class="shadow"
        height="64"
        width="64"
      >
        <v-icon class="material-icons-round text-white" size="24">donut_small</v-icon>
      </v-avatar>
      <div class="ms-4">
        <h4 class="text-h6 text-typo font-weight-bold">Distribuzione Percentuale</h4>
        <p class="font-weight-light text-secondary text-sm">
          Distribuzione delle categorie Vino, Cibo e Bibite
        </p>
      </div>
    </div>
    <div class="card-padding py-0 d-flex">
      <div class="w-50 ms-auto">
        <v-simple-table class="table" height="300px">
          <template v-slot:default>
            <tbody>
              <tr v-for="item in formattedChartData" :key="item.type">
                <td>
                  <div class="d-flex px-2 py-0 align-center">
                    <div>
                      <h6 class="mb-0 ms-2 text-sm text-typo font-weight-bold">
                        {{ item.type }}
                      </h6>
                    </div>
                  </div>
                </td>
                <td class="align-middle text-center text-sm">
                  <span class="text-xs font-weight-bold text-body">
                    {{ item.value }}%
                  </span>
                  <br>
                  <span class="text-xs text-secondary">
                    ({{ item.absolute_value }})
                  </span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div class="chart w-50 pb-4">
        <canvas ref="pie-chart" class="chart-canvas" height="200"></canvas>
      </div>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "TortaDownloadType",
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    formattedChartData() {
      // Verifica che `chartData` esista e sia un array
      if (!Array.isArray(this.chartData) || this.chartData.length === 0) {
        return [];  // Ritorna un array vuoto se non ci sono dati
      }

      const totalRevenue = this.$props.totalRevenue || 0;

      return this.chartData.map(item => {
        // Verifica che `item.percent` sia un numero valido
        const percentValue = (typeof item.percent === 'number' && !isNaN(item.percent)) ? item.percent.toFixed(2) : '0.00';

        // Calcolo del valore assoluto solo se `item.percent` è un numero valido
        const absoluteValue = (typeof item.percent === 'number' && !isNaN(item.percent)) ? (item.percent / 100) * totalRevenue : 0;

        return {
          type: item.type,
          value: percentValue,  // Percentuale formattata con due decimali
          absolute_value: absoluteValue.toFixed(2)  // Valore assoluto con due decimali
        };
      });
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.createChart(); // Ricrea il grafico quando i dati cambiano
      }
    }
  },
  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  methods: {
    createChart() {
    // Verifica che `formattedChartData` contenga dati
    if (this.formattedChartData.length === 0) {
      console.warn("No chart data available");  // Log di avviso se non ci sono dati
      return;
    }

    const labels = this.formattedChartData.map(item => item.type);
    const data = this.formattedChartData.map(item => parseFloat(item.value));  // Converte `value` in numero
    const backgroundColor = ["#e91e63", "#03A9F4", "#3A416F"];

    if (this.chart) {
      this.chart.destroy();  // Distruggi il vecchio grafico se esiste
    }

    const ctx = this.$refs['pie-chart']?.getContext('2d');
    if (!ctx) {
      console.error("Canvas context not found");
      return;  // Evita di continuare se il canvas non è pronto
    }

    this.chart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          data: data,  // Dati già in percentuale
          backgroundColor: backgroundColor,
          borderWidth: 2
        }]
      },
      options: {
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        generateLabels: (chart) => {
          const data = chart.data;

          // Verifica che data.labels e dataset.data esistano
          if (!data || !data.labels || !data.datasets || !data.datasets[0].data) {
            console.warn("Labels or dataset data not defined");
            return [];
          }

          return data.labels.map((label, i) => {
            const dataset = data.datasets[0];
            const value = dataset.data[i] !== undefined ? dataset.data[i] : 0;  // Verifica che dataset.data[i] sia definito

            // Verifica che `value` sia definito e un numero
            const percentValue = (typeof value === 'number' && !isNaN(value)) ? value.toFixed(2) : '0.00';
            const absoluteValue = (typeof value === 'number' && !isNaN(value)) ? value : '0';

            return {
              text: `${label}: ${percentValue}% (${absoluteValue.toFixed(2)})`,  // Mostra percentuale e valore assoluto
              fillStyle: dataset.backgroundColor[i] || '#000',  // Aggiunta verifica per backgroundColor
              strokeStyle: dataset.borderColor ? dataset.borderColor[i] : '#000',
              lineWidth: dataset.borderWidth || 2,
              hidden: chart.getDatasetMeta(0)?.data[i]?.hidden || false,  // Verifica che chart.getDatasetMeta(0).data[i] esista
              index: i
            };
          });
        }
      }
    },
    datalabels: {
      formatter: (value, ctx) => {
        let label = ctx.chart.data.labels[ctx.dataIndex];
        // Verifica che `value` sia un numero
        return (typeof value === 'number' && !isNaN(value)) ? `${label}: ${value.toFixed(2)}%` : `${label}: 0.00%`;
      }
    }
  }
},
      plugins: [ChartDataLabels]
    });
  }
  }
};
</script>
